import React from 'react'

import SEO from '../components/seo'
import About from '../components/About'
import Layout from '../components/layout'

const AboutPage = () => (
    <Layout>
        <SEO title="about:header" />
        <About />
    </Layout>
)

export default AboutPage
